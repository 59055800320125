import React, { Component } from 'react';
import MD5 from 'md5-node';
import { Form, Icon, Input, Button } from 'antd';
import { connect } from 'react-redux';
import { setUserInfo, setUserMenu } from '@/redux/actions/userInfo';
import $axios from '@/axios/$axios';
import '@/assets/css/login';

const FormItem = Form.Item;
class Login extends Component {
	state = { clientHeight: document.documentElement.clientHeight || document.body.clientHeight };
	constructor(props) {
		super(props);
		this.onResize = this.onResize.bind(this);
	}
	login = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				$axios.post("/oil/login",{
					username: values.username,
					password: MD5(values.password)
				}).then(data => {
					if(data.code == '200'){
						localStorage.setItem('isLogin', '1');
						localStorage.setItem('token', data.data);
						this.getUserInfo()
					}
				})
			} else {
				console.log(err);
			}
		});
	};
	getUserInfo(){
		$axios.get("/oil/login/user/info/all").then(data => {
			if(data.code == '200'){
				let sysUserInfo = data.data.sysUserInfo || {}
				let sysUserRights = data.data.sysUserRights || {}
				this.props.setUserInfo({ role: { type: 1, name: sysUserInfo.username} });
				localStorage.setItem('userInfo', JSON.stringify({ 
					info: sysUserInfo,
					role: { type: 1, name: sysUserInfo.username}
				}));

				let icons = ['user', 'folder', 'pay-circle', 'file']
				let menus = sysUserRights.map((item, index) => {
					return {
						path: item.url,
						title: item.name,
						icon: icons[index]
					}
				})

				this.props.setUserMenu(menus)
				localStorage.setItem('userMenu', JSON.stringify(menus));
			
				this.props.history.push('/');
			}
		})
	};
	componentDidMount() {
		window.addEventListener('resize', this.onResize);
	}
	componentWillUnmount() {
		window.addEventListener('resize', this.onResize);
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	onResize() {
		this.setState({ clientHeight: document.documentElement.clientHeight || document.body.clientHeight });
	}
	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<div className="container login-wrap">
				<div className="content">
					<div className="title">撬装加油系统</div>
					<Form className="login-form">
						<FormItem>
							{getFieldDecorator('username', {
								rules: [{ required: true, message: '请填写用户名！' }]
							})(<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />)}
						</FormItem>
						<FormItem>
							{getFieldDecorator('password', {
								rules: [{ required: true, message: '请填写密码！' }]
							})(<Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="密码" />)}
						</FormItem>
						<FormItem>
							<Button type="primary" htmlType="submit" block onClick={this.login}>
								登录
							</Button>
						</FormItem>
					</Form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	},
	setUserMenu: data => {
		dispatch(setUserMenu(data));
	},
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create()(Login));
