import React from 'react'
import Loadable from 'react-loadable';
import { Spin } from 'antd';

const asyncLoader = (loader) => {
  return Loadable({
    loader: loader,
    loading: () => (
        <div style={{ paddingTop: 100, textAlign: 'center' }}>
          <Spin size="large" />
        </div>
    )
  });
};

export default asyncLoader;
