import loadable from '../utils/asyncLoader' 

const Account = loadable(() => import('../views/account/Manage'));
const Stock = loadable(() => import('../views/stock/Manage'));
const Oil = loadable(() => import('../views/oil/Manage'));
const DataSheet = loadable(() => import('../views/statictis/DataSheet'));
// const OilStation = loadable(() => import('../views/oilstation/Manage'));
const Error404 = loadable(() => import('../views/error/Error404'));
const Error500 = loadable(() => import('../views/error/Error500'));


export const routes = [
	{ path: '/account/manage', component: Account },
	{ path: '/stock/manage', component: Stock },
	{ path: '/oil/manage', component: Oil },
	{ path: '/statictis/dataSheet', component: DataSheet },
	// { path: '/oilstation/manage', component: OilStation },油站管理

	{ path: '/error/404', component: Error404 },
	{ path: '/error/500', component: Error500 },
];
