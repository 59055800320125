import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addTag, removeTag, emptyTag } from '@/redux/actions/tagList';
import { Scrollbars } from 'react-custom-scrollbars';

class Tags extends Component {
	handChangeTag(activeKey) {
		if (this.props.location.pathname === activeKey) return;
		this.props.history.push(activeKey);
	}
	handRemoveTag(e, targetKey) {
		e.stopPropagation();
		// 删除页签
		const { tagList } = this.props;
		const { location } = this.props;
		if (location.pathname === targetKey) {
			tagList.forEach(() => {
				this.props.removeTag(targetKey);
				if (targetKey === tagList[tagList.length - 1].path) {
					// 当前页签在最后,删除之后则路由跳转到前一个页签
					this.props.history.replace(tagList[tagList.length - 2].path);
				} else {
					// 当页页签不是在最后,删除之后则跳转到最后去
					this.props.history.replace(tagList[tagList.length - 1].path);
				}
			});
		} else {
			this.props.removeTag(targetKey);
		}
	}
	componentDidMount() {
		const { location, userMenu } = this.props;

		// 默认加入首页
		this.props.addTag({
			title: userMenu[0].title,
			path: userMenu[0].path
		});

		for (let i = 0; i < userMenu.length; i++) {
			if (userMenu[i].children) {
				for (let j = 0; j < userMenu[i].children.length; j++) {
					if (location.pathname === userMenu[i].children[j].path) {
						this.props.addTag({
							title: userMenu[i].children[j].title,
							path: userMenu[i].children[j].path
						});
					} else {
						if (userMenu[i].children[j].children) {
							for (let k = 0; k < userMenu[i].children[j].children.length; k++) {
								if (location.pathname === userMenu[i].children[j].children[k].path) {
									this.props.addTag({
										title: userMenu[i].children[j].children[k].title,
										path: userMenu[i].children[j].children[k].path
									});
								}
							}
						}
					}
				}
			} else {
				if (location.pathname === userMenu[i].path) {
					this.props.addTag({
						title: userMenu[i].title,
						path: userMenu[i].path
					});
				}
			}
		}
	}

	componentDidUpdate(newProps) {
		let tagList = document.querySelector('.tags-list');
		if(!tagList) return
		let arrLi = tagList.querySelectorAll('li');
		let w = 0; // 根据tags计算宽度
		for (var i = 0; i < arrLi.length; i++) {
			w += arrLi[i].offsetWidth + 4;
		}
		tagList.style.width = w + 'px';
	}

	render() {
		const { tagList, location } = this.props;
		if (tagList.length === 0) return null;
		return (
			<div className="tags">
				<div className="tags-scroll">
					<Scrollbars style={{ width: '100%', height: 45 }} autoHide universal={true}>
						<ul className="tags-list">
							{tagList.map((item, index) => (
								<li key={item.path} className={location.pathname === item.path ? 'isActive' : ''} onClick={() => this.handChangeTag(item.path)}>
									<span>{item.title}</span>
									<em onClick={e => this.handRemoveTag(e, item.path)} className={index === 0 ? 'hide' : ''}>
										&times;
									</em>
								</li>
							))}
						</ul>
					</Scrollbars>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	addTag: data => {
		dispatch(addTag(data));
	},
	removeTag: data => {
		dispatch(removeTag(data));
	},
	emptyTag: () => {
		dispatch(emptyTag());
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Tags));
