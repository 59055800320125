import axios from 'axios';
import { message } from 'antd';
const $axios = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	timeout: 6000,
	retry: 4,
	retryDelay: 3000
});

//请求拦截
$axios.interceptors.request.use(
	function(config) {
		// 在发送请求之前做些什么
		// 通过reudx的store拿到拿到全局状态树的token ，添加到请求报文，后台会根据该报文返回status
		// 此处应根据具体业务写toke
		
		const token = localStorage.getItem('token');
		config.headers['Token'] = token;

		if(['post', 'put'].includes(config.method.toLowerCase())){
			config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
			if(config.data){
				const formData = new FormData();
				Object.keys(config.data).forEach((key) => {
					formData.append(key, config.data[key]);
				});
				config.data = formData
			}
		}

		return config;
	},
	function(error) {
		// 对请求错误做些什么
		message.error(error);
		return Promise.reject(error);
	}
);

// 添加响应拦截器
$axios.interceptors.response.use(
	function(response) {
		let whiteList = ["/oil/order/sale/detail"]

		// 对响应数据做点什么
		if(response.data.code == '403'){
			localStorage.clear()
			message.error('登录已过期，请重新登录');
			window.location.href = '/#/login'
		}else if (response.data.code != '200' && !(whiteList.includes(response.config.url) && response.config.method === 'post')) {
			message.error(response.data.msg || '请求失败');
		}

		return response.data || {};

	},
	function(error) {
		if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
			var config = error.config;
			config.__retryCount = config.__retryCount || 0;

			if (config.__retryCount >= config.retry) {
				// Reject with the error
				//window.location.reload();
				return Promise.reject(error);
			}

			// Increase the retry count
			config.__retryCount += 1;

			// Create new promise to handle exponential backoff
			var backoff = new Promise(function(resolve) {
				setTimeout(function() {
					//console.log('resolve');
					resolve();
				}, config.retryDelay || 1);
			});

			return backoff.then(function() {
				return axios(config);
			});
		} else {
			return Promise.reject(error);
		}
	}
);

export default $axios;
